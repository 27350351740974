import * as Yup from 'yup';
const ValidationFormSchema = Yup.object({
  status: Yup.string().required('Required'),
  document: Yup.object().when('status', {
    is: (status) => {
      return status === 'APPROVED' ? true : false;
    },
    then: Yup.object({
      storeName: Yup.string()
        .typeError('Store Name should not be empty')
        .required('Store Name should not be empty'),
      amount: Yup.number()
        .typeError('Amount should not be empty')
        .required('Amount should not be empty'),
      qualifyingAmount: Yup.number()
        .min(1, 'Amount should be greater then zero')
        .required('Qualifying Amount should not be empty'),
      postal: Yup.string()
        .typeError('Zip Code should not be empty')
        .required('Zip Code should not be empty'),
      receiptDate: Yup.string()
        .typeError('Receipt Date should not be empty')
        .required('Receipt Date should not be empty'),

      products: Yup.array()
        .min(1, 'Please Enter at lease one UPC')
        .of(
          Yup.object().shape({
            upcCodeId: Yup.string().required('UPC Code is required'),
            quantity: Yup.number().required('Quantity is required'),
            unitPrice: Yup.number().required('Unit price is required'),
          }),
        )
        // .min(1, 'Please Enter at lease one UPC')
        .nullable()
        .required('Atleast one product UPC is required'),
    }),
  }),
});

export default ValidationFormSchema;
