import React, {Suspense, lazy} from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {LayoutSplashScreen, ContentRoute} from '../_metronic/layout';
import {BuilderPage} from './pages/BuilderPage';
import {MyPage} from './pages/MyPage';
import {DashboardPage} from './pages/DashboardPage';
import Heatmap from './modules/Heatmap/Heatmap';
import {ProductManagement} from './pages/ProductManagement';
//import {ProcessAutomation} from './pages/ProcessAutomation';
import ProcessAutomation from './modules/ProcessAutomation/ProcessAutomationPage';
import {RoleMgmt} from './pages/RoleManagement';
//import {TemplateMgmt} from './pages/TemplateManagement';
import {TestingPanel} from './pages/TestingPanel';
import TemplatePage from './modules/Template/TemplatePage';
// import ReceiptInvalid from './modules/Receipt/ReceiptInvalid';
import RulePage from './modules/Rule/RulePage';
import RolePage from './modules/Role/RolePage';
import RetailersPage from './modules/Retailers/RetailersPage';
import ReceiptSummaryPage from './modules/ReceiptSummary/ReceiptSummaryPage';
import RewardSummaryPage from './modules/RewardSummary/RewardSummaryPage';
import RequestedReportsPage from './modules/RequestedReports/RequestedReportsPage';
import PincodeSummary from './modules/RewardSummary/pages/PincodeSummary';
import CampaignMappingPage from './modules/CampaignMapping/CampaignMappingPage';
import ChangePasswordPage from './modules/ChangePassword/pages/ChangePasswordPage';
import TrackingReportsPage from './modules/TrackingReportsPage';
import RBAC from './modules/RBAC';
import ReceiptView from './modules/Receipt/ReceiptView';
const GoogleMaterialPage = lazy(() =>
  import('./modules/GoogleMaterialExamples/GoogleMaterialPage'),
);
const ReactBootstrapPage = lazy(() =>
  import('./modules/ReactBootstrapExamples/ReactBootstrapPage'),
);
const ECommercePage = lazy(() =>
  import('./modules/ECommerce/pages/eCommercePage'),
);
const ActivityPage = lazy(() => import('./modules/Activity/ActivityPage'));
const ProductPage = lazy(() => import('./modules/Product/ProductPage'));
const UserProfilepage = lazy(() =>
  import('./modules/UserProfile/UserProfilePage'),
);
const OfferPage = lazy(() => import('./modules/Offer/OfferPage'));

const CampaignPage = lazy(() => import('./modules/Campaign/CampaignPage'));
const CampaignListOld = lazy(() =>
  import('./modules/Compaign/pages/CampaignPages'),
);
const ReceiptMain = lazy(() => import('./modules/Receipt/ReceiptMain'));
const SearchForm = lazy(() => import('./modules/Receipt/SearchForm'));
const HelpDeskPage = lazy(() => import('./modules/HelpDesk/HelpDeskPage'));
const UserPage = lazy(() => import('./modules/User/UserPage'));
const AdminUserPage = lazy(() => import('./modules/AdminUser/AdminUserPage'));
const ClientUserPage = lazy(() =>
  import('./modules/ClientUser/ClientUserPage'),
);
const RewardsPage = lazy(() => import('./modules/Reward/RewardsPage'));
const CodeGenerationPage = lazy(() =>
  import('./modules/CodeGeneration/CodeGenerationPage'),
);
const OrderPage = lazy(() => import('./modules/Order/OrderPage'));
const ContestPage = lazy(() => import('./modules/Contest/ContestPage'));
const DynamicPage = lazy(() => import('./modules/DynamicMessage/DynamicPage'));
const RetailersList = lazy(() => import('./modules/Retailers/RetailersPage'));
const GameCenter = lazy(() => import('./modules/GameCenter'));
const Redemption = lazy(() => import('./modules/Redemption'));
const Backup = lazy(() => import('./modules/Backup'));
const UserInfo = lazy(() => import('./modules/UserInfo/index'));

export default function BasePage() {
  let redirctUrl = new URLSearchParams(window.location.search).get('routes');
  redirctUrl = redirctUrl ? redirctUrl : '';
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect
            exact
            from="/"
            to={
              redirctUrl.indexOf('receipt/all/') >= 0 ? redirctUrl : '/campaign'
            }
          />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        {/* <ContentRoute path="/offers" component={OffersManagement} /> */}
        <ContentRoute path="/products" component={ProductManagement} />
        <ContentRoute
          path="/receipt/all/:campaignId-:receiptId"
          render={(props) => <ReceiptView receiptUrl={''} {...props} />}
        />
        <ContentRoute
          path="/receipt/all"
          render={(props) => <ReceiptMain receiptUrl={''} {...props} />}
        />
        <ContentRoute
          path="/receipt/new"
          render={(props) => <ReceiptMain receiptUrl={'new'} {...props} />}
        />
        <ContentRoute
          path="/receipt/ready"
          render={(props) => <ReceiptMain receiptUrl={'ready'} {...props} />}
        />
        <ContentRoute
          path="/receipt/invalid"
          render={(props) => <ReceiptMain receiptUrl={'invalid'} {...props} />}
        />
        <ContentRoute
          path="/receipt/pending"
          render={(props) => <ReceiptMain receiptUrl={'pending'} {...props} />}
        />
        <ContentRoute
          path="/receipt/approved"
          render={(props) => <ReceiptMain receiptUrl={'approved'} {...props} />}
        />
        <ContentRoute
          path="/receipt/rejected"
          render={(props) => <ReceiptMain receiptUrl={'rejected'} {...props} />}
        />
        <ContentRoute
          path="/receipt/ambiguous"
          render={(props) => (
            <ReceiptMain receiptUrl={'ambiguous'} {...props} />
          )}
        />
        <ContentRoute
          path="/receipt/search"
          render={(props) => <ReceiptMain receiptUrl={'search'} {...props} />}
        />
        <ContentRoute
          path="/receipt/duplicate"
          render={(props) => (
            <ReceiptMain receiptUrl={'duplicate'} {...props} />
          )}
        />
        {/* <ContentRoute path="/receipt/new" component={ReceiptNew} /> */}
        {/* <ContentRoute path="/receipt/invalid" component={ReceiptInvalid} /> */}
        {/* <ContentRoute path="/receipt/pending" component={PendingReceipt} />
        <ContentRoute path="/receipt/approved" component={ApprovedReceipt} /> */}
        {/* <ContentRoute path="/receipt/rejected" component={RejectedReceipt} />
        <ContentRoute path="/receipt/ambiguous" component={AmbiguousReceipt} /> */}
        <ContentRoute path="/receipt/searchform" component={SearchForm} />
        {/* <ContentRoute path="/receipt/search" component={ResultFilter} /> */}
        {/* <ContentRoute path="/client-mgmt" component={ClientRegistration} /> */}
        {/* <ContentRoute exact path="/campaign" component={CampaignList} /> */}
        <ContentRoute path="/campaign" component={CampaignPage} />
        {/* <ContentRoute exact path="/campaign/add" component={Tenantmgmt} />
        <ContentRoute exact path="/campaign/:id" component={Tenantmgmt} /> */}
        <ContentRoute path="/role-mgmt" component={RoleMgmt} />
        {/* <ContentRoute path="/template-mgmt" component={TemplateMgmt} /> */}
        <ContentRoute
          path="/process-automation"
          component={ProcessAutomation}
        />
        <ContentRoute path="/testing-panel" component={TestingPanel} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />e
        <Route path="/activity" component={ActivityPage} />
        <Route path="/helpdesk" component={HelpDeskPage} />
        <Route path="/product" component={ProductPage} />
        <Route path="/offers" component={OfferPage} />
        <Route path="/user" component={UserPage} />
        <Route path="/heatmap" component={Heatmap} />
        <Route path="/client-mgmt" component={AdminUserPage} />
        <Route path="/changepassword" component={ChangePasswordPage} />
        <Route path="/clientusers" component={ClientUserPage} />
        <Route path="/template" component={TemplatePage} />
        <Route path="/codegeneration" component={CodeGenerationPage} />
        <Route path="/rewards" component={RewardsPage} />
        <Route path="/rule" component={RulePage} />
        <Route path="/role" component={RolePage} />
        <Route path="/dynamic" component={DynamicPage} />
        <Route path="/retailers" component={RetailersPage} />
        <Route path="/receiptsummary" component={ReceiptSummaryPage} />
        <Route path="/rewardsummary" component={RewardSummaryPage} />
        <Route path="/requestedreports" component={RequestedReportsPage} />
        <Route path="/tracking-reports" component={TrackingReportsPage} />
        <Route path="/campaignMapping" component={CampaignMappingPage} />
        <Route path="/pincodereport" component={PincodeSummary} />
        <Route path="/order" component={OrderPage} />
        <Route path="/s-campaign" component={CampaignPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route
          path="/contest/questionnaires/:tab?/:action?/:id?"
          component={GameCenter}
          exact
        />
        <Route path="/contest/:contestType" component={ContestPage} />
        <Route path="/redemption" component={Redemption} />
        <Route path="/backup" component={Backup} />
        <Route path="/user-info/:id/:tab?" component={UserInfo} />
        <Route path="/rbac" component={RBAC} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
