import React, {useEffect, useState} from 'react';
import {Row, Button} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import {Formik, FieldArray, Field} from 'formik';
import {TextField, MenuItem} from '@material-ui/core';
import {connect, useSelector} from 'react-redux';
import IsLoadingHOC from '../../../../_metronic/layout/components/HOC/IsLoadingHOC';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InputAdornment from '@material-ui/core/InputAdornment';
import AsyncCreatableSelect from 'react-select/async-creatable';
import moment from 'moment';
import ValidationFormSchema from '../Schema/ValidationFormSchema.Schema';
import jwt from 'jwt-decode';
import {
  updateReceipt,
  removeFromCheckout,
  getProductListSearchByNameAndUPC,
  getReceiptByid,
  getLookUpData,
  getLockupAction,
  refreshReceiptById,
} from '../_redux/action';
import Modal from 'react-bootstrap/Modal';
import {initialValues} from '../utils';
//import TimerCircle from '../../../../_metronic/_partials/TimerCircle';
//import {checkTimeDiff, checkUpdateAvailability, lockedByOther} from '../util';

import ErrorBoundary from '../../../../_metronic/_helpers/ErrorBoundary';

const colourStyles = {
  option: (styles, {isDisabled}) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#ffffff' : styles.backgroundColor || null,
      color: isDisabled ? '#000000' : styles.color || null,
    };
  },
};

const ReceiptForm = ({
  data,
  setLoading,
  handleRefresh,
  updateReceipt,
  batchId,
  getProductListSearchByNameAndUPC,
  viewOnly,
}) => {
  const [inputValue, setValue] = useState('');
  const [show, setShow] = useState(false);
  const [diable, setdiable] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const handleClose = () => setShow(false);
  const [hideSubmitBtn, setHideSubmitBtn] = useState(false);
  let [receiptTypeLst, setReceiptTypeLst] = useState([]);
  const [receiptData, setReceiptData] = useState(null);
  const {accessToken} = useSelector((state) => state.auth);
  const userData = jwt(accessToken);
  useEffect(() => {
    const roles = userData?.roles || [];
    if (roles.length > 0) {
      let fltRolls = roles.filter((v) => v.toUpperCase() === 'MAILINTEAM');
      if (fltRolls.length > 0) {
        setHideSubmitBtn(true);
      }
    }
  }, [userData]);
  let rejectionArrayList = [
    {
      name: 'Duplicate Submission',
      id: 'DUPLICATE_SUBMISSION',
    },
    {
      name: 'Content',
      id: 'CONTENT',
    },
    {
      name: 'Illegible Receipt',
      id: 'ILLEGiBLE_RECEIPT',
    },
    {
      name: 'Invalid Receipt',
      id: 'INVALID_RECEIPT',
    },
    {
      name: 'Invalid Store',
      id: 'INVALID_STORE',
    },
    {
      name: 'Invalid ZIP/Postal Code',
      id: 'INVALID_ZIP',
    },
    {
      name: 'Missing Product',
      id: 'MISSING_PRODUCT',
    },
    {
      name: 'Incomplete Claim Form',
      id: 'INCOMPLETE_CLAIM_FORM',
    },
    {
      name: 'Receipt Not Submitted',
      id: 'RECEIPT_NOT_SUBMITTED',
    },
    {
      name: 'No Purchase Date',
      id: 'NO_PURCHASE_DATE',
    },
    {
      name: 'Program Limit Reached',
      id: 'PROGRAM_LIMIT_REACHED',
    },
    {
      name: 'Testing',
      id: 'TESTING',
    },
    {
      name: 'Fraud',
      id: 'FRAUD',
    },
    {
      name: 'Other',
      id: 'OTHER',
    },
  ];
  const [receiptRejectionList, setReceiptRejectionList] =
    useState(rejectionArrayList);

  useEffect(() => {
    getLookUpData()
      .then((res) => {
        if (res.content) {
          if (Array.isArray(res.content))
            setReceiptRejectionList(res.content[0].data);
          else setReceiptRejectionList(rejectionArrayList);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);
  const updateReceiptData = (id, newValues) => {
    setLoading(true);
    setdiable(true);
    updateReceipt(id, newValues)
      .then((res) => {
        // console.log(res)
        setLoading(false);

        //handleRefresh();
        setdiable(false);

        //   toast.success('Updated Template');
      })
      .catch((err) => {
        setdiable(false);
        setLoading(false);
      });
  };
  const updateStatus = (values) => {
    const {
      document: {qualifyingAmount},
    } = values;
    let updateValues = {
      ...values,
      document: {
        ...values.document,
        qualifyingAmount: Number(qualifyingAmount).toFixed(2),
      },
    };

    let newValues;
    if (batchId) {
      newValues = batchId && {...updateValues, batchId: batchId};
    } else {
      newValues = updateValues;
    }
    if (
      newValues?.document?.products &&
      newValues?.document.products.length > 0
    ) {
      for (let i in newValues.document.products) {
        let qty = newValues.document.products[i].quantity;
        let unitPrice = newValues.document.products[i].unitPrice;
        let productAmount = newValues.document.products[i].productAmount;
        let qualifiedPoints = newValues.document.products[i].qualifiedPoints;
        qty = qty === '' ? 0 : parseFloat(qty);
        unitPrice = unitPrice === '' ? 0 : parseFloat(unitPrice);
        productAmount = productAmount === '' ? 0 : parseFloat(productAmount);
        qualifiedPoints =
          qualifiedPoints === '' ? 0 : parseFloat(qualifiedPoints);
        newValues.document.products[i].quantity = qty;
        newValues.document.products[i].unitPrice = unitPrice;
        newValues.document.products[i].productAmount = productAmount;
        newValues.document.products[i].qualifiedPoints = qualifiedPoints;
      }
    }
    if (newValues?.status.toUpperCase() === 'APPROVED') {
      if (
        parseInt(newValues?.document?.amount || 0) < 0 ||
        parseInt(newValues?.document?.qualifyingAmount || 0) < 0 ||
        parseInt(newValues?.document?.amount || 0) <
          parseInt(newValues?.document?.qualifyingAmount || 0)
      ) {
        setConfirmBox(true);
        setReceiptData({id: values?.id, data: newValues});
      } else {
        updateReceiptData(values?.id, newValues);
      }
    } else {
      updateReceiptData(values?.id, newValues);
    }
  };

  const onAddfield = (values, setValues) => {
    if (values?.document?.products && values?.document.products.length > 0) {
      const products = [...values.document.products];

      products.push({
        upcCodeId: '',
        quantity: 1,
        unitPrice: 0,
        productAmount: 0,
        productName: '',
        isApproved: true,
        qualifiedPoints: 0,
        terraformId: null,
        id: '',
      });
      setValues({
        ...values,
        document: {...values.document, products: products},
      });
    } else {
      const FirstProduct = {
        upcCodeId: '',
        productName: '',
        quantity: 1,
        unitPrice: 0,
        productAmount: 0,
        isApproved: true,
        qualifiedPoints: 0,
        terraformId: null,
        id: '',
      };

      setValues({
        ...values,
        document: {
          ...values.document,
          products: [FirstProduct],
        },
      });
    }
  };

  const loadOptions = (value) => {
    return getProductListSearchByNameAndUPC(value || inputValue);
  };
  const handleInputChange = (value) => {
    setValue(value);
  };
  const productName = (values, i) => {
    let obj = {
      label: '',
      value: '',
    };
    const products = values?.document?.products;
    if (Array.isArray(products)) {
      obj.label = products[i].upcCodeId
        ? `(${products[i].upcCodeId}) ${products[i].productName}`
        : products[i].productName;
      obj.value = `${products[i].upcCodeId}`;
      //   obj.name = products[i].productName ? products[i].productName : '';
    }
    return obj;
  };

  const calculateAmount = (values, setFieldValue, name, i, value) => {
    let totalAmount = 0;
    let valuesArray = values.document.products;
    if (name === 'remove') {
      valuesArray = values.document.products.filter(
        (item, index) => index !== i,
      );
    }

    valuesArray.forEach((item, index) => {
      if (name === 'productName') {
        if (index === i) {
          totalAmount = totalAmount + item.quantity * item.unitPrice;
        } else {
          if (item.isApproved) {
            totalAmount = totalAmount + item.quantity * item.unitPrice;
          }
        }
      } else {
        if (item.isApproved) {
          if (name === 'quantity') {
            if (index === i) {
              totalAmount = totalAmount + value * item.unitPrice;
            } else {
              totalAmount = totalAmount + item.quantity * item.unitPrice;
            }
          }
          if (name === 'unitPrice') {
            // totalAmount = totalAmount + item.quantity * item.productAmount;
            if (index === i) {
              totalAmount = totalAmount + item.quantity * value;
            } else {
              totalAmount = totalAmount + item.quantity * item.unitPrice;
            }
          }
          if (name === 'remove') {
            totalAmount = totalAmount + item.quantity * item.unitPrice;
          }
        }
      }
    });

    setFieldValue('document.qualifyingAmount', totalAmount);
  };

  const handleRejection = (values) => {
    let newValues = {};
    for (let key in values) {
      if (key in values) {
        newValues[key] = values[key];
      }
    }

    newValues['status'] = 'REJECTED';

    updateStatus(newValues);
    setShow(false);
  };
  useEffect(() => {
    let requestData = {lookups: ['RECEIPT_TYPE']};
    setLoading(true);
    getLockupAction(requestData)
      .then((response) => {
        if (response.httpCode === 200 && response.data) {
          if (response.data.content) {
            let stListInd = response.data.content.findIndex(
              (v) => v.name === 'RECEIPT_TYPE',
            );
            if (stListInd >= 0) {
              let reptDataList = response.data.content[stListInd].data.map(
                (v) => {
                  return {...v, label: v.name, value: v.id};
                },
              );
              setReceiptTypeLst(reptDataList);
            }
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <ErrorBoundary>
      <Paper
        elevation={3}
        border={0}
        className="p-6 rounded-0 d-flex flex-column  justify-content-between align-items-center">
        <Row className="w-100">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td className="col-sm-3">
                  <h6>First Name</h6>
                </td>
                <td className="col-sm-2">: </td>
                <td className="td-values">{data?.userInfo?.firstName}</td>
              </tr>
              <tr>
                <td className="col-sm-3">
                  <h6>Last Name</h6>
                </td>
                <td className="col-sm-2">:</td>
                <td className="td-values">{data?.userInfo?.lastName}</td>
              </tr>
              <tr>
                <td className="col-sm-3">
                  <h6>Email</h6>
                </td>
                <td className="col-sm-2">: </td>
                <td className="td-values">{data?.userInfo?.email}</td>
              </tr>
              <tr>
                <td className="col-sm-3">
                  <h6>Member Id</h6>
                </td>
                <td className="col-sm-2">: </td>
                <td className="td-values">{data?.userInfo?.memberId}</td>
              </tr>
            </tbody>
          </table>
        </Row>

        <div className="form-group w-100 ">
          <Formik
            initialValues={
              {
                ...initialValues,
                ...data,
                actionType: 'submit',
                scope: null,
              } || {
                ...initialValues,
                actionType: 'submit',
                scope: null,
              }
            }
            enableReinitialize
            onSubmit={(values) => {
              let newValues = {};
              for (let key in values) {
                if (key in values) {
                  newValues[key] = values[key];
                }
              }
              updateStatus(newValues);
            }}
            validationSchema={ValidationFormSchema}
            //  validator={() => ({})}
            onReset={() => {}}>
            {({
              values,
              //handleReset,
              handleSubmit,
              handleChange,
              //handleBlur,
              touched,
              errors,
              //field,
              setValues,
              setFieldValue,
            }) => (
              <form>
                {console.log('.......', errors)}
                <fieldset>
                  <div className="col-lg-12 col-xl-12 col-xs-12 mb-2">
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      className="w-100"
                      name="document.storeName"
                      id="standard-basic"
                      label="Store Name"
                      onChange={handleChange}
                      disabled={viewOnly}
                      value={
                        values?.document?.storeName
                          ? values?.document?.storeName
                          : ''
                      }
                    />
                    <div className="text-danger">
                      {errors?.document?.storeName}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 col-xs-12 mb-2">
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      disabled={viewOnly}
                      className="w-100"
                      name="document.memberId"
                      id="standard-basic"
                      label="Receipt Member ID"
                      onChange={handleChange}
                      value={values?.document?.memberId || ''}
                    />
                    <div className="text-danger">{errors?.memberId}</div>
                  </div>
                  <div className="col-lg-12 col-xl-12 col-xs-12 mb-2">
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      disabled={viewOnly}
                      className="w-100"
                      name="receiptUploadEmail"
                      id="standard-basic"
                      label="Receipt Email"
                      onChange={handleChange}
                      value={
                        values?.receiptUploadEmail
                          ? values?.receiptUploadEmail
                          : ''
                      }
                    />
                    <div className="text-danger">
                      {errors?.receiptUploadEmail}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      disabled={viewOnly}
                      className="w-100"
                      name="document.amount"
                      id="standard-basic"
                      type="number"
                      label="Total Basket Size"
                      onChange={handleChange}
                      value={values?.document ? values?.document?.amount : ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon style={{height: '.8em'}} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="text-danger">{errors.document?.amount}</div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      disabled={viewOnly}
                      className="w-100"
                      name="document.qualifyingAmount"
                      id="standard-basic"
                      label="Total Qualifying Products Purchased"
                      type="number"
                      onChange={handleChange}
                      value={
                        values?.document
                          ? values?.document?.qualifyingAmount
                          : ''
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon style={{height: '.8em'}} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="text-danger">
                      {errors.document?.qualifyingAmount}
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      disabled={viewOnly}
                      className="w-100"
                      name="document.postal"
                      id="standard-basic"
                      label="Zip Code"
                      onChange={handleChange}
                      value={
                        values?.document?.postal ? values?.document?.postal : ''
                      }
                    />
                    <div className="text-danger">{errors.document?.postal}</div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <TextField
                      name="date"
                      type="date"
                      className="w-100"
                      id="standard-basic"
                      label="Submission Date"
                      onChange={handleChange}
                      disabled={viewOnly}
                      value={
                        values?.date
                          ? `${moment(values?.date).format('YYYY-MM-DD')}`
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#ccc'},
                      }}
                      InputProps={{
                        inputProps: {min: '1970-05-01', max: '9999-12-31'},
                      }}
                    />
                    <div className="text-danger">{errors.date}</div>
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <TextField
                      name="document.receiptDate"
                      type="date"
                      className="w-100"
                      id="standard-basic"
                      label="Receipt Date"
                      onChange={handleChange}
                      disabled={viewOnly}
                      value={
                        values?.document?.receiptDate
                          ? `${moment(values?.document?.receiptDate).format(
                              'YYYY-MM-DD',
                            )}`
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: {color: '#ccc'},
                      }}
                      InputProps={{
                        inputProps: {min: '1970-05-01', max: '9999-12-31'},
                      }}
                    />
                    <div className="text-danger">
                      {errors?.document?.receiptDate}
                    </div>
                  </div>

                  <div className="col-lg-12 col-xl-12">
                    {
                      <FieldArray name="document.products">
                        {(arrayHelpers) =>
                          values?.document?.products &&
                          values?.document?.products.length > 0 ? (
                            values?.document?.products.map((products, i) => {
                              const productErrors =
                                (errors?.products?.length &&
                                  errors?.document?.products[i]) ||
                                {};
                              const productTouched = products;

                              return (
                                <div
                                  key={i}
                                  className="list-group list-group-flush">
                                  <div className="form-row">
                                    <div className="form-group col-8 mb-0">
                                      <Field
                                        name={`document.products.${i}.upcCodeId`}
                                        className={
                                          'form-control' +
                                          (productErrors.upcCodeId &&
                                          productTouched.upcCodeId
                                            ? ' is-invalid'
                                            : '')
                                        }>
                                        {({
                                          field, // { name, value, onChange, onBlur }
                                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                          meta,
                                        }) => (
                                          <>
                                            <AsyncCreatableSelect
                                              {...field}
                                              styles={colourStyles}
                                              isClearable
                                              isDisabled={viewOnly}
                                              defaultOptions={
                                                productName(values, i) &&
                                                productName(values, i).label
                                                  ? [
                                                      {
                                                        ...productName(
                                                          values,
                                                          i,
                                                        ),
                                                        isDisabled: true,
                                                      },
                                                    ]
                                                  : []
                                              }
                                              onChange={(value) => {
                                                if (value?.value) {
                                                  const valueNew = value.value;
                                                  const valueArray =
                                                    valueNew.split('-');

                                                  setFieldValue(
                                                    `document.products.${i}.upcCodeId`,
                                                    valueArray[0],
                                                  );
                                                  setFieldValue(
                                                    `document.products.${i}.id`,
                                                    valueArray[1],
                                                  );

                                                  setFieldValue(
                                                    `document.products.${i}.isApproved`,
                                                    valueArray.length > 1
                                                      ? true
                                                      : false,
                                                  );

                                                  const name =
                                                    valueArray.length > 1
                                                      ? value.label.slice(
                                                          valueArray[0].length +
                                                            2,
                                                        )
                                                      : value.label;

                                                  setFieldValue(
                                                    `document.products.${i}.productName`,
                                                    name.trim(),
                                                  );

                                                  calculateAmount(
                                                    values,
                                                    setFieldValue,
                                                    `productName`,
                                                    i,
                                                    true,
                                                  );
                                                }
                                              }}
                                              onInputChange={handleInputChange}
                                              value={productName(values, i)}
                                              loadOptions={loadOptions}
                                              name={`document.products.${i}.upcCodeId`}
                                              label="UPC Code or Description"
                                              className={`${
                                                values?.document?.products
                                                  ? values?.document?.products
                                                      ?.length > 0
                                                    ? values?.document
                                                        ?.products[i].isApproved
                                                      ? 'approvel-product'
                                                      : 'unapproved-product'
                                                    : 'unapproved-product'
                                                  : 'unapproved-product'
                                              }`}
                                              classNamePrefix="select"
                                            />
                                          </>
                                        )}
                                      </Field>
                                      {errors?.document?.products?.length > 0 &&
                                      touched?.document?.products?.length > 0 &&
                                      touched?.document?.products[i] ? (
                                        <div className="text-danger">
                                          {
                                            errors?.document?.products[i]
                                              ?.upcCodeId
                                          }
                                        </div>
                                      ) : null}{' '}
                                    </div>

                                    <div className="form-group col-2 mb-0">
                                      <Field
                                        name={`document.products.${i}.unitPrice`}>
                                        {({
                                          field, // { name, value, onChange, onBlur }
                                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                          meta,
                                        }) => (
                                          <>
                                            <TextField
                                              InputLabelProps={{
                                                style: {color: '#ccc'},
                                              }}
                                              disabled={viewOnly}
                                              //{...field}
                                              value={
                                                values?.document?.products[i]
                                                  .unitPrice
                                                  ? values?.document?.products[
                                                      i
                                                    ]?.unitPrice
                                                  : ''
                                              }
                                              name={`document.products.${i}.unitPrice`}
                                              type="text"
                                              className={
                                                'form-control ' +
                                                (productErrors.unitPrice &&
                                                productTouched.unitPrice
                                                  ? ' is-invalid'
                                                  : '')
                                              }
                                              onChange={(e) => {
                                                const name = e.target.name;
                                                let value = e.target.value;
                                                if (
                                                  value != '.' &&
                                                  isNaN(value) &&
                                                  value != '-'
                                                )
                                                  return;
                                                handleChange({
                                                  target: {name, value},
                                                });

                                                calculateAmount(
                                                  values,
                                                  setFieldValue,
                                                  `unitPrice`,
                                                  i,
                                                  e.target.value,
                                                );
                                              }}
                                              label="Unit Price"
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <AttachMoneyIcon
                                                      style={{height: '.8em'}}
                                                    />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                            {/* <ErrorMessage
                                              name={`document.products.${i}.productAmount`}
                                              component="div"
                                              className="invalid-feedback"
                                            /> */}
                                          </>
                                        )}
                                      </Field>
                                      {errors?.document?.products?.length > 0 &&
                                      touched?.document?.products?.length > 0 &&
                                      touched?.document?.products[i] ? (
                                        <div className="text-danger">
                                          {
                                            errors?.document?.products[i]
                                              ?.unitPrice
                                          }
                                        </div>
                                      ) : null}{' '}
                                    </div>
                                    <div className="form-group col-1 mb-0">
                                      <Field
                                        name={`document.products.${i}.quantity`}>
                                        {({
                                          field, // { name, value, onChange, onBlur }
                                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                          meta,
                                        }) => (
                                          <>
                                            <TextField
                                              InputLabelProps={{
                                                style: {color: '#ccc'},
                                              }}
                                              disabled={viewOnly}
                                              {...field}
                                              value={
                                                values?.document?.products[i]
                                                  .quantity
                                                  ? values?.document?.products[
                                                      i
                                                    ]?.quantity
                                                  : ''
                                              }
                                              name={`document.products.${i}.quantity`}
                                              type="number"
                                              min="0"
                                              className={
                                                'form-control' +
                                                (productErrors.quantity &&
                                                productTouched.quantity
                                                  ? ' is-invalid'
                                                  : '')
                                              }
                                              onChange={(e) => {
                                                handleChange(e);

                                                calculateAmount(
                                                  values,
                                                  setFieldValue,
                                                  `quantity`,
                                                  i,
                                                  e.target.value,
                                                );
                                              }}
                                              label="Qty"
                                            />
                                          </>
                                        )}
                                      </Field>
                                      {errors?.document?.products?.length > 0 &&
                                      touched?.document?.products?.length > 0 &&
                                      touched?.document?.products[i] ? (
                                        <div className="text-danger">
                                          {
                                            errors?.document?.products[i]
                                              ?.quantity
                                          }
                                        </div>
                                      ) : null}{' '}
                                    </div>
                                    <div className="form-group col-1 mb-0">
                                      <button
                                        disabled={viewOnly}
                                        className="btn m-0 p-1 w-50 mt-xl-4"
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.remove(i);
                                          calculateAmount(
                                            values,
                                            setFieldValue,
                                            `remove`,
                                            i,
                                            true,
                                          );
                                        }} // remove a  from the list
                                      >
                                        -
                                      </button>
                                      <button
                                        disabled={viewOnly}
                                        className="btn m-0 p-1 w-50 mt-xl-4"
                                        type="button"
                                        onClick={() =>
                                          onAddfield(values, setValues)
                                        } // insert an empty string at a position
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <button
                              className="btn m-0 p-1"
                              type="button"
                              onClick={() => onAddfield(values, setValues)} // insert an empty string at a position
                            >
                              Add UPC +
                            </button>
                          )
                        }
                      </FieldArray>
                    }
                    {errors?.document?.products &&
                    touched?.document?.products ? (
                      <div className="text-danger">
                        {!Array.isArray(errors?.document?.products) &&
                          errors?.document?.products}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      disabled={viewOnly}
                      className="w-100"
                      name="lines"
                      multiline
                      rows={5}
                      id="standard-basic"
                      label="Description"
                      onChange={handleChange}
                      value={
                        values?.lines || ''
                        // ? JSON.stringify(JSON.parse(values?.lines), null, 2)
                        // : ''
                      }
                    />
                    {receiptTypeLst.length > 0 && (
                      <TextField
                        InputLabelProps={{
                          style: {color: '#ccc'},
                        }}
                        disabled={viewOnly}
                        name={`additionalInfo.submissionType`}
                        id="standard-select-currency"
                        select
                        label="Receipt upload source"
                        className="w-100"
                        onChange={handleChange}
                        value={values?.additionalInfo?.submissionType}>
                        {receiptTypeLst.map((v, ind) => (
                          <MenuItem value={v.value} key={`key-${ind}`}>
                            {v.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    <TextField
                      InputLabelProps={{
                        style: {color: '#ccc'},
                      }}
                      name="status"
                      id="standard-select-currency"
                      select
                      disabled={viewOnly}
                      label="Select"
                      className="w-100"
                      value={values?.status.toUpperCase()}
                      onChange={(e) => {
                        const {name, value} = e.target;
                        if (value === 'REJECTED') {
                          setShow(true);
                        } else {
                          if (values.declineReason != '')
                            setFieldValue('declineReason', '');
                          setFieldValue(name, value);
                        }
                      }}
                      helperText="Please select your status">
                      <MenuItem value="PENDING">PENDING</MenuItem>
                      <MenuItem value="DUPLICATE">DUPLICATE</MenuItem>
                      <MenuItem value="INVALID">INVALID</MenuItem>
                      <MenuItem value="AMBIGUOUS">AMBIGUOUS</MenuItem>
                      <MenuItem value="APPROVED">APPROVED</MenuItem>
                      <MenuItem value="REJECTED">REJECTED</MenuItem>
                      <MenuItem value="READY">READY</MenuItem>
                      <MenuItem value="NEW">NEW</MenuItem>
                    </TextField>

                    <div className="text-danger">{errors.status}</div>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        what is the Reason for Decline ?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <TextField
                        name="declineReason"
                        id="standard-select-currency"
                        select
                        label="Reason for decline"
                        className="w-100"
                        value={values?.declineReason}
                        onChange={handleChange}
                        helperText="Please select Decline Reason">
                        {receiptRejectionList.map((v, ind) => (
                          <MenuItem value={v.name} key={`key-${ind}`}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        disabled={values?.declineReason ? false : true}
                        variant="primary"
                        onClick={() => {
                          handleRejection(values);
                          // setFieldValue('status', 'REJECTED');
                          //setShow(false);
                        }}>
                        Okay
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={confirmBox}
                    onHide={(e) => setConfirmBox(!confirmBox)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Validate the Total Basket Size or Total Qualifying
                        Products Purchased amounts.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={(e) => setConfirmBox(false)}>
                        No
                      </Button>
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          setConfirmBox(false);
                          updateReceiptData(receiptData.id, receiptData.data);
                        }}>
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {!viewOnly && (
                    <div className="col-lg-12 col-xl-12 my-4  mb-2">
                      {!hideSubmitBtn && (
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue('actionType', 'submit');
                            setFieldValue('scope', null);
                            handleSubmit();
                          }}
                          className="btn btn-danger ml-0 mr-2 rounded-0"
                          disabled={diable}>
                          Submit
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue('actionType', 'submit');
                          setFieldValue('scope', 'save');
                          handleSubmit();
                        }}
                        className="btn btn-primary mr-2 rounded-0">
                        Save
                      </button>
                    </div>
                  )}
                </fieldset>
              </form>
            )}
          </Formik>
        </div>
      </Paper>
    </ErrorBoundary>
  );
};

export default connect(null, {
  updateReceipt,
  removeFromCheckout,
  getProductListSearchByNameAndUPC,
  getReceiptByid,
})(IsLoadingHOC(ReceiptForm));
