import api from '../../../../api';
import {
  SAVE_RECEIPT_LIST,
  // SAVE_NEW_RECEIPT_LIST,
  // SAVE_PENDING_RECEIPT_LIST,
  // SAVE_APPROVED_RECEIPT_LIST,
  // SAVE_REJECTED_RECEIPT_LIST,
  // SAVE_AMBIGUOUS_RECEIPT_LIST,
  SAVE_SEARCH_RESULT_RECEIPT_LIST,
  SAVE_RECEIPT_LIST_NEW,
  REMOVED_FROM_CHEKOUT,
  Add_TO_CHEKOUT,
  UPDATE_RECEIPT_DATA,
  REFRES_RECEIPT,
} from './type';
import {toast} from 'react-toastify';
export const getReceiptList =
  (urlParam = '', page = 0, limit = 8, order, filters = null) =>
  async (dispatch) => {
    let url = '/s1/receipt';
    if (urlParam === 'search') {
      url += `/${urlParam}`;
    } else {
      if (!urlParam || urlParam === '') {
        urlParam = 'all';
      }
      url += `/list/${urlParam}`;
    }
    return new Promise(async (resolve, reject) => {
      let data;
      if (!filters) {
        data = await api.get(
          `${url}?page=${page}&limit=${limit}&sort=${order ? order : 'asc'}`,
        );
      } else {
        data = await api.post(
          `${url}?page=${page}&limit=${limit}&sort=${order ? order : 'asc'}`,
          filters,
        );
      }

      if (data.httpCode === 200) {
        dispatch({type: SAVE_RECEIPT_LIST, payload: data.data});
        resolve(data.data);
        // } else {
        //   dispatch({type: SAVE_RECEIPT_LIST_NEW, payload: data.data});
        // }
        resolve('done');
      } else {
        //reject(data.message);
        dispatch({type: SAVE_RECEIPT_LIST, payload: []});
        reject('error');
        data?.errors &&
          data.errors.forEach((item) => toast.error(item.message));
        //dispatch({type: LOADING, payload: false});
      }
    });
  };

export const updateReceipt = (id, values) => async (dispatch) => {
  values.document.qualifyingAmount = +values.document.qualifyingAmount;
  values.document.amount = +values.document.amount;
  values.document.products =
    values.document.products && values.document.products.length
      ? [...values.document.products].map((item) => ({
          ...item,
          unitPrice: +item.unitPrice,
          productAmount: +item.unitPrice * +item.quantity,
          isApproved: item.isApproved || item.id ? true : false,
          nonParticipate: item.id ? false : true,
        }))
      : [];

  return new Promise(async (resolve, reject) => {
    let actionType = values?.actionType || 'submit';
    let scope = values?.scope || null;
    let data = await api.put(
      `/s1/receipt/${id}?action=${actionType}${scope ? `&scope=${scope}` : ''}`,
      values,
    );
    // let data = await api.post(`/s2/receipt/${id}`, values);
    if (data.httpCode === 200) {
      resolve(data.message);
      toast.success('Receipts updated successfully');
      dispatch({
        type: REMOVED_FROM_CHEKOUT,
        payload: {id: id, uId: '', data: values},
      });
      // dispatch({type: UPDATE_RECEIPT_DATA, payload: {id: id, data: values}});
      //dispatch({type: SAVE_Product_LIST, payload: data.data});
    } else {
      reject(data.errors);
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
    }
  });
};

export const occupieReceipt = (id, uId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/receipt/isoccupied', {id: id});
    if (data.httpCode === 200) {
      resolve(data.message);
      //  toast.success(data.message);
      //  toast.success('Receipt Locked');
      dispatch({
        type: Add_TO_CHEKOUT,
        payload: {id: id, uId: uId},
      });
    } else {
      reject(data.errors);
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
    }
  });
};
export const refreshReceiptById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/receipt/${id}`);
    if (data.httpCode === 200) {
      let similarReptData = await api.get(`/s1/duplicate-receipt/${id}`);
      if (similarReptData.httpCode === 200) {
        data.data.similarReceipts =
          similarReptData?.data || data?.data?.similarReceipts;
      }
      resolve(data.message);
      dispatch({
        type: REFRES_RECEIPT,
        payload: data.data,
      });
    } else {
      reject(data.errors);
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
    }
  });
};
export const getReceiptByid = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s2/receipt/get/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      //  toast.success(data.message);
      // toast.success('Receipt Locked');
      dispatch({
        type: REFRES_RECEIPT,
        payload: data.data,
      });
    } else {
      reject(data.errors);
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
    }
  });
};

export const removeFromCheckout = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post(`/s2/receipt/timeout/${id}`);
    if (data.httpCode === 200) {
      resolve(data.message);
      // toast.success(data.message);
      toast.success('Receipt unlocked');

      dispatch({
        type: REMOVED_FROM_CHEKOUT,
        payload: {id: id, uId: ''},
      });
    } else {
      reject(data.errors);
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
    }
  });
};

export const getSearchResult = (filters) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.post('/s1/receipt/search/0/100', filters);
    if (data.httpCode === 200) {
      resolve(data.message);
      dispatch({
        type: SAVE_SEARCH_RESULT_RECEIPT_LIST,
        payload: data.data,
      });
      dispatch({type: 'SAVE_FILTER', payload: filters});
    } else {
      reject(data?.errors);
      dispatch({type: SAVE_SEARCH_RESULT_RECEIPT_LIST, payload: []});
      data?.errors && data.errors.forEach((item) => toast.error(item.message));
      // dispatch({type: LOADING, payload: false});
    }
  });
};

export const getSearchListNextPage =
  (filters, page = 0, limit = 10) =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let data = await api.post(`/s1/receipt/search/${page}/${limit}`, filters);
      if (data.httpCode === 200) {
        resolve(data.message);
        dispatch({
          type: SAVE_SEARCH_RESULT_RECEIPT_LIST,
          payload: data.data,
        });
        //    dispatch({type: 'SAVE_FILTER', payload: filters});
      } else {
        reject(data?.errors);
        dispatch({type: SAVE_SEARCH_RESULT_RECEIPT_LIST, payload: []});
        data?.errors &&
          data.errors.forEach((item) => toast.error(item.message));
        // dispatch({type: LOADING, payload: false});
      }
    });
  };

export const getProductListSearchByNameAndUPC = (name) => async (dispatch) => {
  // console.log('check methos');
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/product?limit=1000&searchText=${btoa(name)}`);
    //  console.log('woreking nroor', data);
    if (data.httpCode === 200) {
      // console.log('data', data.data);
      const newData = data.data?.content?.map((item) => {
        return {
          label: `(${item.UPC}) ${item.productName}`,
          value: `${item.UPC}-${item.id}`,
        };
      });

      resolve(newData ? newData : []);
    } else {
      reject([]);
    }
  });
};

export const getHistoryList = (id, page, sizePerPage) => async (dispatch) => {
  // console.log('check methos');
  return new Promise(async (resolve, reject) => {
    let data = await api.get(
      `/s1/receipt/history/${id}?page=${page - 1}&limit=${sizePerPage}`,
    );
    //  console.log('woreking nroor', data);
    if (data.httpCode === 200) {
      //console.log('data receipt list', data.data);
      // const newData = data.data?.content?.map((item) => {
      //   return {
      //     label: `(${item.UPC}) ${item.productName}`,
      //     value: `${item.UPC}-${item.id}`,
      //   };
      // });

      resolve(data.data);
    } else {
      reject([]);
    }
  });
};

export const getLookUpData = async () => {
  return new Promise(async (resolve, reject) => {
    let params = {
      lookups: ['RECEIPTS_REJECTION_REASONS'],
    };
    let data = await api.post('/s1/lookup', params);
    if (data.httpCode === 200) {
      resolve(data.data);
    } else {
      reject(data.message);
    }
  });
};
export const getReceiptDataById = async (receipt_id) => {
  return new Promise(async (resolve, reject) => {
    let data = await api.get(`/s1/receipt/${receipt_id}`);
    if (data.httpCode === 200) {
      let similarReptData = await api.get(
        `/s1/duplicate-receipt/${receipt_id}`,
      );
      if (similarReptData.httpCode === 200) {
        data.data.similarReceipts =
          similarReptData?.data || data?.data?.similarReceipts;
      }
      resolve(data.data);
    } else {
      reject(data.message);
    }
  });
};
export const getLockupAction = (data) => {
  return api.post('/s1/lookup', data);
};
